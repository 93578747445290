var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',[_c('BaseBox',{staticClass:"\n      px-4\n      py-5\n      bg-white\n      shadow-md\n      rounded-lg\n      overflow-hidden\n      sm:p-6\n      grid\n      gap-4\n      relative\n    "},[_c('BaseBox',{staticClass:"absolute right-16 md:right-8 top-3"},[_c('BaseBox',{staticClass:"flex rounded-full bg-gray-300 p-1 text-lg focus-within:ring-2"},[_c('BaseBox',{class:[
            'relative rounded-full py-1 px-2',
            _vm.isForSaleCategory
              ? 'text-gray-50 bg-tertiary rounded-full transition duration-200'
              : 'text-gray-600 cursor-pointer rounded-full transition duration-200' ],attrs:{"tag":"label","for":"selected-category-sales"}},[_c('BaseInputRadio',{staticClass:"\n              absolute\n              inset-0\n              pointer-events-none\n              w-full\n              h-full\n              opacity-0\n            ",attrs:{"id":"selected-category-sales","value":_vm.PropertyCategoryId.ForSale},model:{value:(_vm.selectedCategoryId),callback:function ($$v) {_vm.selectedCategoryId=$$v},expression:"selectedCategoryId"}}),_c('BaseText',[_c('i18n',{attrs:{"tag":false,"path":"routes.forSale"}})],1)],1),_c('BaseBox',{class:[
            'relative rounded-full py-1 px-2',
            !_vm.isForSaleCategory
              ? 'text-gray-50 bg-tertiary transition duration-200'
              : 'text-gray-600 cursor-pointer transition duration-200' ],attrs:{"tag":"label","for":"selected-category-rentals"}},[_c('BaseInputRadio',{staticClass:"\n              absolute\n              inset-0\n              pointer-events-none\n              w-full\n              h-full\n              opacity-0\n            ",attrs:{"id":"selected-category-rentals","value":_vm.PropertyCategoryId.Rentals},model:{value:(_vm.selectedCategoryId),callback:function ($$v) {_vm.selectedCategoryId=$$v},expression:"selectedCategoryId"}}),_c('BaseText',[_c('i18n',{attrs:{"tag":false,"path":"routes.rentals"}})],1)],1)],1)],1),_c('BaseBox',{staticClass:"pt-12 sm:pt-0"},[_c('BaseBox',[_c('BaseHeading',{staticClass:"text-xl",attrs:{"level":1}},[_c('BaseIcon',{staticClass:"mr-2",attrs:{"name":"coins"}}),_c('i18n',{attrs:{"tag":false,"path":_vm.titlePath}})],1)],1),_c('BaseBox',[_c('BaseBox',{staticClass:"grid md:grid-cols-3 lg:grid-cols-5 gap-5"},[_c('BaseBox',[_c('CardProperty',{attrs:{"title":_vm.activeLabel,"value":_vm.activeCount}})],1),_c('BaseBox',[_c('CardProperty',{attrs:{"title":_vm.deletedLabel,"value":_vm.deletedCount}})],1),_c('BaseBox',[_c('CardProperty',{attrs:{"title":_vm.visitedLabel,"value":_vm.visitedCount}})],1),_c('BaseBox',[_c('CardProperty',{attrs:{"title":_vm.avgMax,"value":_vm.averageMaxPrice}})],1),_c('BaseBox',[_c('CardProperty',{attrs:{"title":_vm.avgMin,"value":_vm.averageMinPrice}})],1)],1)],1)],1),_c('BaseBox',[_c('BaseBox',{staticClass:"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5"},[_c('DashboardFilterTable',{attrs:{"title":_vm.areasLabel,"info":_vm.filterAreas,"icon":"map-marker-alt"}}),_c('DashboardFilterTable',{attrs:{"title":_vm.typesLabel,"info":_vm.filterTypes,"icon":"building"}}),_c('BaseBox',[_c('SiteChart',{attrs:{"type":'pie',"data":_vm.typesChartData}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }