import { Color } from '@/design/system/helpers'
import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { api } from '../client'
import {
  GuidIdTableType,
  IdReturnViewModel,
  IntIdTableType,
  PaginationList,
  PaginationSelectModel,
  serviceUrl,
} from '../utils'
import { AgentViewModel } from './agent'
import { AreaViewModel } from './area'
import { CategoryViewModel } from './category'
import { TagViewModel } from './tag'
import { TypeViewModel } from './type'
import { CurrencyViewModel } from './currency'

const controller = serviceUrl('property')

function createPropertyService(client: AxiosInstance) {
  return {
    get(data: PropertyModel, config?: AxiosRequestConfig) {
      return client.post<PropertyGetViewModel>(
        controller.action('get'),
        data,
        config
      )
    },

    insert(data: PropertyInsertModel) {
      return client.post<IdReturnViewModel>(controller.action('insert'), data)
    },

    update(data: PropertyUpdateModel) {
      return client.post<IdReturnViewModel>(controller.action('update'), data)
    },
  }
}

export const propertyService = createPropertyService(api.base)

export type PropertyGetViewModel = PaginationList<PropertyViewModel>

export interface PropertyModel extends PaginationSelectModel {
  languageId?: string
  isRecommendation?: boolean
  isFeatureListing?: boolean
  isDeleted?: boolean
  bottomLatitude?: string
  topLatitude?: string
  leftLongitude?: string
  rightLongitude?: string
  propertyKey?: number
  categoryId?: string
  agentKey?: number
  bathroom?: number
  bedroom?: number
  minPrice?: number
  minPrice2?: number
  maxPrice?: number
  maxPrice2?: number
  orderBy?: string
  excludePropertyKey?: number
  propertyTypeList?: GuidIdTableType[]
  propertyAreaList?: GuidIdTableType[]
  propertyKeyList?: IntIdTableType[]
  AvailableRentalStartingDate?: Date
  AvailableRentalEndingDate?: Date
}

export interface PropertyViewModel {
  propertyId: string
  propertyKey: number
  mlsPropertyId: string
  price: number
  price2: number
  cleaningFee: number
  bathroom: number
  bedroom: number
  isRecommendation: boolean
  isFeatureListing: boolean
  isDeleted: boolean
  virtualTour: string
  displayOrder: number
  title: string
  description: string
  addressViewModel: AddressViewModel
  areaViewModel: AreaViewModel
  cityViewModel: CityViewModel
  stateViewModel: StateViewModel
  countryViewModel: CountryViewModel
  agentViewModel: AgentViewModel
  typeViewModel: TypeViewModel
  categoryViewModel: CategoryViewModel
  tagViewModel: TagViewModel | null
  currencyViewModel: CurrencyViewModel
  propertyImageList: PropertyImageViewModel[]
  propertyGroupList: PropertyGroupViewModel[]
  calendarList: CalendarViewModel[]
}

export interface AddressViewModel {
  addressId: string
  addressKey: number
  name: string
  lineOne: string
  lineTwo: string
  postalCode: string
  latitude: string
  longitude: string
}

export interface CityViewModel {
  cityId: string
  cityKey: number
  name: string
}

export interface StateViewModel {
  stateId: string
  stateKey: number
  name: string
}

export interface CountryViewModel {
  countryId: string
  countryKey: number
  name: string
}

export interface ToneViewModel {
  toneId: string
  toneKey: number
  name: Color
}

export interface PropertyImageViewModel {
  imageId: string
  imageKey: number
  propertyId: string
  name: string
  description: string | null
  path: string
  displayOrder: number
}

export interface PropertyGroupViewModel {
  groupId: string
  groupKey: number
  name: string
  title: string
  description: string
  detailList: PropertyDetailViewModel[]
}

export interface PropertyDetailViewModel {
  detailId: string
  detailKey: number
  name: string
  description: string
  propertyDetailTitle: string
  propertyDetailDescription: string
  value: string
  groupId: string
  propertyId: string
}

export enum PropertyCategoryId {
  ForSale = '74e39077-4d67-eb11-9ffe-00155e010303',
  Rentals = '75e39077-4d67-eb11-9ffe-00155e010303',
}

export interface PropertyInsertModel {
  areaId?: string
  agentId?: string
  typeId?: string
  categoryId?: string
  tagId?: string
  currencyId?: string
  addressName: string
  addressLineOne: string
  addressLineTwo: string
  addressPostalCode: string
  addressLatitude: string
  addressLongitude: string
  mlsPropertyId: string
  price?: number
  price2?: number
  cleaningFee?: number
  bathroom?: number
  bedroom?: number
  isRecommendation?: boolean
  isFeatureListing?: boolean
  isDeleted?: boolean
  virtualTour: string
  displayOrder?: number
  detailValueTableType: DetailValueTableType[]
  propertyTextTableType: PropertyTextTableType[]
  calendarTableType: CalendarTableType[]
  fileTableType: FileTableType[]
}

export interface PropertyUpdateModel {
  propertyId: string
  areaId?: string
  agentId?: string
  typeId?: string
  categoryId?: string
  tagId?: string
  currencyId?: string
  addressId?: string
  addressName: string
  addressLineOne: string
  addressLineTwo: string
  addressPostalCode: string
  addressLatitude: string
  addressLongitude: string
  mlsPropertyId: string
  price?: number
  price2?: number
  cleaningFee?: number
  bathroom?: number
  bedroom?: number
  isRecommendation?: boolean
  isFeatureListing?: boolean
  isDeleted?: boolean
  virtualTour: string
  displayOrder?: number
  detailValueTableType: DetailValueTableType[]
  propertyTextTableType: PropertyTextTableType[]
  calendarTableType: CalendarTableType[]
  fileTableType: FileTableType[]
  fileListToDelete: FileTableType[]
}

export interface DetailValueTableType {
  detailId: string
  languageId: string
  value: string
}

export interface PropertyTextTableType {
  propertyId: string
  languageId: string
  title: string
  description: string
}

export interface FileTableType {
  dataUrl: string
  name: string
  description: string
  extension: string
  path: string
  displayOrder: number
}

export interface CalendarTableType {
  startingDate: Date
  endingDate: Date
}

export interface CalendarViewModel {
  propertyId: string
  startingDate: string
  endingDate: string
}
