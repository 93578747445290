



























import CardPropertySkeleton from '@/components/CardPropertySkeleton.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CardSkeleton',

  components: {
    CardPropertySkeleton,
  },
})
