










































import i18n from '@/setup/i18n'
import { defineComponent, computed } from '@vue/composition-api'
import CardProperty from './CardProperty.vue'

export default defineComponent({
  name: 'BoostedProperties',

  components: {
    CardProperty,
  },

  props: {
    recommended: {
      type: Number,
      required: true,
    },
    boosted: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const featuredLabel = computed(() => i18n.t('featured') as string)
    const recommendedLabel = computed(() => i18n.t('recommended') as string)

    return {
      featuredLabel,
      recommendedLabel,
    }
  },
})
