import { DashboardModel, dashboardService } from '@/services/modules/dashboard'
import { currentLanguage } from '@/utils/language'
import { computed, ComputedRef, isRef } from '@vue/composition-api'
import { useService } from './service'

export function useDashboard(
  model: ComputedRef<DashboardModel> | DashboardModel
) {
  const request = computed<DashboardModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...(isRef(model) ? model.value : model),
  }))

  return useService(request, () => dashboardService.get(request.value))
}
