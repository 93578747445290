








































import {
  FilterAreaList,
  FilterTypeList,
  PropertyFilterCountList,
  AgentFilterCountList,
  OrderByFilterCountList,
} from '@/services/modules/dashboard'
import i18n from '@/setup/i18n'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'
import { VueGoodTableColumn } from '@/setup/vue-good-table'

interface ColumnsFilterTable
  extends FilterAreaList,
    FilterTypeList,
    PropertyFilterCountList,
    AgentFilterCountList,
    OrderByFilterCountList {
  type: string
  thClass?: string
  tdClass?: string
}

export default defineComponent({
  name: 'DashboardFilterTable',

  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: Array as PropType<
        | FilterAreaList[]
        | FilterTypeList[]
        | PropertyFilterCountList[]
        | AgentFilterCountList[]
        | OrderByFilterCountList[]
      >,
      required: true,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
  },

  setup(props) {
    const searchByPlaceholder = computed(() => i18n.t('searchBy') as string)

    const columns = computed<VueGoodTableColumn<ColumnsFilterTable>[]>(() => [
      {
        label: i18n.t('name') as string,
        field: 'name',
        type: 'text',
      },
      {
        label: i18n.t('count') as string,
        field: 'count',
        type: 'number',
        thClass: 'text-left',
        tdClass: 'flex items-start',
      },
    ])

    const filterQuery = ref('')
    const filterArray = ref<
      | FilterAreaList[]
      | FilterTypeList[]
      | PropertyFilterCountList[]
      | AgentFilterCountList[]
      | OrderByFilterCountList[]
    >(props.info)

    watch(filterQuery, (currentValue) => {
      filterArray.value = props.info.filter((item) =>
        item.name.toLocaleLowerCase().includes(currentValue.toLocaleLowerCase())
      )
    })

    watch(
      () => props.info,
      (currentValue) => {
        filterQuery.value = ''
        filterArray.value = currentValue
      }
    )

    return {
      searchByPlaceholder,
      columns,
      filterQuery,
      filterArray,
    }
  },
})
