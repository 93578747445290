












import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CardProperty',

  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
  },
})
