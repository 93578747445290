var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"grid gap-5"},[_c('BaseBox',{staticClass:"flex justify-end items-center"},[_c('BaseFieldLabel',{staticClass:"mr-1",attrs:{"html-for":"date-filter","label":_vm.dateRangeLabel}}),_c('BaseSelect',{staticClass:"w-full md:w-1/3 lg:w-1/6",attrs:{"id":"date-filter","options":_vm.dashboardDateFilterOptions,"show-labels":false,"label":"title","track-by":"key"},model:{value:(_vm.dashboardFilterOption),callback:function ($$v) {_vm.dashboardFilterOption=$$v},expression:"dashboardFilterOption"}})],1),_c('BaseBox',{class:[
      'px-4 py-5  shadow-md rounded-lg overflow-hidden sm:p-6 grid gap-4 relative',
      _vm.isLoadingDashboardInfo ? 'bg-gray-200 animate-pulse' : 'bg-white' ]},[(!_vm.isLoadingDashboardInfo)?[_c('BaseHeading',{staticClass:"text-2xl",attrs:{"level":1}},[_c('BaseIcon',{staticClass:"mr-1",attrs:{"name":"city"}}),_c('i18n',{attrs:{"tag":false,"path":"allProperties"}})],1)]:[_c('BaseBox',{staticClass:"bg-gray-50 h-8 w-full md:w-1/2 lg:w-1/4 rounded"})],_c('BaseBox',{staticClass:"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5"},[_c('BaseBox',[(!_vm.isLoadingDashboardInfo)?[_c('CardProperty',{attrs:{"title":_vm.activeLabel,"value":_vm.dashboardInfo.totalActiveCount}})]:[_c('CardPropertySkeleton')]],2),_c('BaseBox',[(!_vm.isLoadingDashboardInfo)?[_c('CardProperty',{attrs:{"title":_vm.deletedLabel,"value":_vm.dashboardInfo.totalIsDeletedCount}})]:[_c('CardPropertySkeleton')]],2)],1)],2),_c('BaseBox',[(!_vm.isLoadingDashboardInfo)?[(!_vm.isLoadingDashboardInfo)?_c('DashboardCategorySection',{attrs:{"dashboard-info":_vm.dashboardInfo}}):_vm._e()]:[_c('DashboardCategorySectionSkeleton')]],2),_c('BaseBox',{staticClass:"grid grid-cols-1 lg:grid-cols-3 gap-5"},[_c('BaseBox',[(!_vm.isLoadingDashboardInfo)?[_c('LocaleCard',{attrs:{"spanish":_vm.dashboardInfo.spanishFilterCount,"english":_vm.dashboardInfo.englishFilterCount}})]:[_c('CardSkeleton')]],2),_c('BaseBox',{staticClass:"lg:col-span-2"},[(!_vm.isLoadingDashboardInfo)?[_c('BoostedProperties',{attrs:{"recommended":_vm.dashboardInfo.isRecommendationCount,"boosted":_vm.dashboardInfo.isFeatureListingCount}})]:[_c('CardSkeleton')]],2)],1),_c('BaseBox',{staticClass:"grid grid-cols-1 lg:grid-cols-3 gap-5"},[_c('BaseBox',{class:[
        'px-4 py-5 shadow-md rounded-lg overflow-hidden sm:p-6 grid gap-4',
        _vm.isLoadingDashboardInfo ? 'bg-gray-200 animate-pulse' : 'bg-white' ]},[(!_vm.isLoadingDashboardInfo)?[_c('DashboardFilterTable',{attrs:{"title":_vm.propertiesLabel,"info":_vm.dashboardInfo.propertyFilterCountList,"icon":"home"}})]:[_c('DashboardFilterTableSkeleton')]],2),_c('BaseBox',{class:[
        'px-4 py-5 shadow-md rounded-lg overflow-hidden sm:p-6 grid gap-4',
        _vm.isLoadingDashboardInfo ? 'bg-gray-200 animate-pulse' : 'bg-white' ]},[(!_vm.isLoadingDashboardInfo)?[_c('DashboardFilterTable',{attrs:{"title":_vm.agentLabel,"info":_vm.dashboardInfo.agentFilterCountList,"icon":"user"}})]:[_c('DashboardFilterTableSkeleton')]],2),_c('BaseBox',{class:[
        'px-4 py-5 shadow-md rounded-lg overflow-hidden sm:p-6 grid gap-4',
        _vm.isLoadingDashboardInfo ? 'bg-gray-200 animate-pulse' : 'bg-white' ]},[(!_vm.isLoadingDashboardInfo)?[_c('DashboardFilterTable',{attrs:{"title":_vm.orderByLabel,"info":_vm.dashboardInfo.orderByFilterCountList,"icon":"sort-alpha-down"}})]:[_c('DashboardFilterTableSkeleton')]],2)],1),_c('BaseBox',{staticClass:"grid grid-cols-1 lg:grid-cols-3 gap-5"},[(!_vm.isLoadingDashboardInfo)?[_c('DashboardTopFiveChart',{attrs:{"title":_vm.topFivePropertiesLabel,"data":_vm.dashboardInfo.propertyFilterCountList}})]:[_c('DashboardTopFiveChartSkeleton')],(!_vm.isLoadingDashboardInfo)?[_c('DashboardTopFiveChart',{attrs:{"title":_vm.topFiveAreaSalesLabel,"data":_vm.areasSalesData,"type":0}})]:[_c('DashboardTopFiveChartSkeleton')],(!_vm.isLoadingDashboardInfo)?[_c('DashboardTopFiveChart',{attrs:{"title":_vm.topFiveAreaRentalsLabel,"data":_vm.areasRentalsData,"type":1}})]:[_c('DashboardTopFiveChartSkeleton')]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }