










































import i18n from '@/setup/i18n'
import { defineComponent, computed } from '@vue/composition-api'
import CardProperty from './CardProperty.vue'

export default defineComponent({
  name: 'LocaleCard',

  components: {
    CardProperty,
  },

  props: {
    spanish: {
      type: Number,
      required: true,
    },
    english: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const spanishLabel = computed(() => i18n.t('spanish') as string)
    const englishLabel = computed(() => i18n.t('english') as string)

    return {
      spanishLabel,
      englishLabel,
    }
  },
})
