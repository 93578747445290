import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'

const controller = serviceUrl('dashboard')

function createDashboardService(client: AxiosInstance) {
  return {
    get(params: DashboardModel) {
      return client.get<DashboardViewModel>(controller.action('get'), {
        params,
      })
    },
  }
}

export const dashboardService = createDashboardService(api.base)

export interface DashboardModel {
  languageId?: string
  startingDate?: Date | null
  endingDate?: Date | null
  isDeleted?: boolean
}

export interface DashboardViewModel {
  salesActiveCount: number
  salesIsDeletedCount: number
  rentalActiveCount: number
  rentalIsDeletedCount: number
  totalActiveCount: number
  totalIsDeletedCount: number
  spanishFilterCount: number
  englishFilterCount: number
  rentalFilterCount: number
  salesFilterCount: number
  isRecommendationCount: number
  isFeatureListingCount: number
  averageMaxPrice: number
  averageMaxPrice2: number
  averageMinPrice: number
  averageMinPrice2: number
  propertyFilterCountList: PropertyFilterCountList[]
  agentFilterCountList: AgentFilterCountList[]
  orderByFilterCountList: OrderByFilterCountList[]
  filterTypeList: FilterTypeList[]
  filterAreaList: FilterAreaList[]
}

export interface PropertyFilterCountList {
  name: string
  category: string
  count: number
}

export interface AgentFilterCountList {
  name: string
  category: string
  count: number
}

export interface OrderByFilterCountList {
  name: string
  category: string
  count: number
}

export interface FilterTypeList {
  name: string
  category: string
  count: number
}

export interface FilterAreaList {
  name: string
  category: string
  count: number
}
