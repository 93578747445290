


















import {
  FilterAreaList,
  PropertyFilterCountList,
} from '@/services/modules/dashboard'
import SiteChart from '@/components/SiteChart.vue'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import _ from 'lodash'

export default defineComponent({
  name: 'DashboardTopFiveChart',

  components: {
    SiteChart,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array as PropType<FilterAreaList[] | PropertyFilterCountList[]>,
      required: true,
    },
    type: {
      type: Number,
      required: false,
      default: 2,
    },
  },

  setup(props) {
    const bgColors = [
      [
        'rgba(252, 105, 45, 0.8)',
        'rgba(252, 105, 45, 0.7)',
        'rgba(252, 105, 45, 0.6)',
        'rgba(252, 105, 45, 0.5)',
        'rgba(252, 105, 45, 0.4)',
      ],
      [
        'rgba(9, 75, 183, 0.8)',
        'rgba(9, 75, 183, 0.7)',
        'rgba(9, 75, 183, 0.6)',
        'rgba(9, 75, 183, 0.5)',
        'rgba(9, 75, 183, 0.4)',
      ],
      [
        'rgba(255, 99, 132, 0.8)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 205, 86, 0.6)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(54, 162, 235, 0.4)',
      ],
    ]

    const topFiveData = computed(() => {
      let sortedArr = _.orderBy(props.data, ['count'], ['desc']).slice(0, 5)
      let data = {
        labels: sortedArr.map((item) => item.name),
        datasets: [
          {
            label: props.title,
            data: sortedArr.map((item) => item.count),
            backgroundColor: bgColors[props.type],
            borderColor: bgColors[props.type],
            borderWidth: 1,
          },
        ],
      }

      return data
    })

    return {
      topFiveData,
    }
  },
})
