













































































import CardPropertySkeleton from '@/components/CardPropertySkeleton.vue'
import DashboardFilterTableSkeleton from '@/components/DashboardFilterTableSkeleton.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'DashboardCategorySectionSkeleton',

  components: {
    CardPropertySkeleton,
    DashboardFilterTableSkeleton,
  },
})
