





























































































































import CardProperty from '@/components/CardProperty.vue'
import DashboardFilterTable from '@/components/DashboardFilterTable.vue'
import SiteChart from '@/components/SiteChart.vue'
import { DashboardViewModel } from '@/services/modules/dashboard'
import { PropertyCategoryId } from '@/services/modules/property'
import i18n from '@/setup/i18n'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'DashboardCategorySection',

  components: {
    CardProperty,
    DashboardFilterTable,
    SiteChart,
  },

  props: {
    dashboardInfo: {
      type: Object as PropType<DashboardViewModel>,
      required: true,
    },
  },

  setup(props) {
    const activeLabel = computed(() => i18n.t('actives') as string)
    const deletedLabel = computed(() => i18n.t('deleted') as string)
    const visitedLabel = computed(() => i18n.t('visited') as string)
    const avgMax = computed(() => i18n.t('avgMax') as string)
    const avgMin = computed(() => i18n.t('avgMin') as string)
    const areasLabel = computed(() => i18n.t('areas') as string)
    const typesLabel = computed(() => i18n.t('types') as string)

    const selectedCategoryId = ref(PropertyCategoryId.ForSale)

    const isForSaleCategory = computed(
      () => selectedCategoryId.value === PropertyCategoryId.ForSale
    )

    const titlePath = computed(() =>
      isForSaleCategory.value ? 'routes.forSale' : 'routes.rentals'
    )

    const activeCount = computed(() =>
      isForSaleCategory.value
        ? props.dashboardInfo.salesActiveCount
        : props.dashboardInfo.rentalActiveCount
    )

    const deletedCount = computed(() =>
      isForSaleCategory.value
        ? props.dashboardInfo.salesIsDeletedCount
        : props.dashboardInfo.rentalIsDeletedCount
    )

    const visitedCount = computed(() =>
      isForSaleCategory.value
        ? props.dashboardInfo.salesFilterCount
        : props.dashboardInfo.rentalFilterCount
    )

    const averageMaxPrice = computed(() =>
      isForSaleCategory.value
        ? '$' + props.dashboardInfo.averageMaxPrice.toFixed(2)
        : '$' + props.dashboardInfo.averageMaxPrice2.toFixed(2)
    )

    const averageMinPrice = computed(() =>
      isForSaleCategory.value
        ? '$' + props.dashboardInfo.averageMinPrice.toFixed(2)
        : '$' + props.dashboardInfo.averageMinPrice2.toFixed(2)
    )

    const filterAreas = computed(() => {
      let arr = props.dashboardInfo.filterAreaList
      if (isForSaleCategory.value) {
        return arr.filter((item) => item.category === 'Sales')
      } else {
        return arr.filter((item) => item.category === 'Rentals')
      }
    })

    const filterTypes = computed(() => {
      let arr = props.dashboardInfo.filterTypeList
      if (isForSaleCategory.value) {
        return arr.filter((item) => item.category === 'Sales')
      } else {
        return arr.filter((item) => item.category === 'Rentals')
      }
    })

    const bgColors = computed(() => {
      if (isForSaleCategory.value) {
        return [
          'rgba(252, 105, 45, 0.8)',
          'rgba(252, 105, 45, 0.7)',
          'rgba(252, 105, 45, 0.6)',
          'rgba(252, 105, 45, 0.5)',
          'rgba(252, 105, 45, 0.4)',
        ]
      } else {
        return [
          'rgba(9, 75, 183, 0.8)',
          'rgba(9, 75, 183, 0.7)',
          'rgba(9, 75, 183, 0.6)',
          'rgba(9, 75, 183, 0.5)',
          'rgba(9, 75, 183, 0.4)',
        ]
      }
    })

    const typesChartData = ref({
      labels: filterTypes.value.map((item) => item.name),
      datasets: [
        {
          label: isForSaleCategory.value ? 'Sales Types' : 'Rentals Types',
          data: filterTypes.value.map((item) => item.count),
          backgroundColor: bgColors.value,
        },
      ],
    })

    watch(filterTypes, (currentValue) => {
      typesChartData.value = {
        labels: currentValue.map((item) => item.name),
        datasets: [
          {
            label: isForSaleCategory.value ? 'Sales Types' : 'Rentals Types',
            data: currentValue.map((item) => item.count),
            backgroundColor: bgColors.value,
          },
        ],
      }
    })

    return {
      activeLabel,
      deletedLabel,
      visitedLabel,
      areasLabel,
      typesLabel,
      avgMax,
      avgMin,
      selectedCategoryId,
      titlePath,
      isForSaleCategory,
      PropertyCategoryId,
      activeCount,
      deletedCount,
      visitedCount,
      averageMaxPrice,
      averageMinPrice,
      filterAreas,
      filterTypes,
      typesChartData,
    }
  },
})
