




















































































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import CardProperty from '@/components/CardProperty.vue'
import CardPropertySkeleton from '@/components/CardPropertySkeleton.vue'
import DashboardFilterTable from '@/components/DashboardFilterTable.vue'
import DashboardFilterTableSkeleton from '@/components/DashboardFilterTableSkeleton.vue'
import DashboardTopFiveChart from '@/components/DashboardTopFiveChart.vue'
import DashboardTopFiveChartSkeleton from '@/components/DashboardTopFiveChartSkeleton.vue'
import { useDashboard } from '@/composition/dashboard'
import { DashboardModel } from '@/services/modules/dashboard'
import DashboardCategorySection from '@/components/DashboardCategorySection.vue'
import DashboardCategorySectionSkeleton from '@/components/DashboardCategorySectionSkeleton.vue'
import i18n from '@/setup/i18n'
import LocaleCard from '@/components/LocaleCard.vue'
import CardSkeleton from '@/components/CardSkeleton.vue'
import BoostedProperties from '@/components/BoostedProperties.vue'
import { startOfMonth, startOfWeek } from 'date-fns'

interface DashboardDateFilterOption {
  key: string
  title: string
  startingDate: Date | null
  endingDate: Date | null
}

export default defineComponent({
  name: 'Dashboard',

  components: {
    CardProperty,
    CardPropertySkeleton,
    DashboardCategorySection,
    DashboardCategorySectionSkeleton,
    LocaleCard,
    CardSkeleton,
    BoostedProperties,
    DashboardFilterTable,
    DashboardFilterTableSkeleton,
    DashboardTopFiveChart,
    DashboardTopFiveChartSkeleton,
  },

  setup() {
    const today = new Date()

    const defaultDashboardFilterOption = {
      key: 'all',
      title: i18n.t('all') as string,
      startingDate: null,
      endingDate: null,
    }

    const dashboardDateFilterOptions = computed<DashboardDateFilterOption[]>(
      () => [
        {
          key: 'week',
          title: i18n.t('thisWeek') as string,
          startingDate: startOfWeek(today),
          endingDate: null,
        },
        {
          key: 'two-weeks',
          title: i18n.t('pastTwoWeeks') as string,
          startingDate: startOfWeek(today),
          endingDate: null,
        },
        {
          key: 'month',
          title: i18n.t('thisMonth') as string,
          startingDate: startOfMonth(today),
          endingDate: null,
        },
        defaultDashboardFilterOption,
      ]
    )

    const dashboardFilterOption = ref<DashboardDateFilterOption | null>(
      defaultDashboardFilterOption
    )

    const model = computed<DashboardModel>(() => ({
      startingDate: dashboardFilterOption.value?.startingDate,
      endingDate: dashboardFilterOption.value?.endingDate,
      isDeleted: false,
    }))

    const { data, isLoading: isLoadingDashboardInfo } = useDashboard(model)
    const dashboardInfo = computed(() => data.value[0])

    const activeLabel = computed(() => i18n.t('actives') as string)
    const deletedLabel = computed(() => i18n.t('deleted') as string)
    const dateRangeLabel = computed(() => i18n.t('dateRange') as string)
    const propertiesLabel = computed(
      () => i18n.t('routes.properties') as string
    )
    const agentLabel = computed(() => i18n.t('agent') as string)
    const orderByLabel = computed(() => i18n.t('orderBy') as string)
    const topFivePropertiesLabel = computed(
      () => i18n.t('topFiveProperties') as string
    )
    const topFiveAreaSalesLabel = computed(
      () => i18n.t('topFiveAreaSales') as string
    )
    const topFiveAreaRentalsLabel = computed(
      () => i18n.t('topFiveAreaRentals') as string
    )

    watch(
      () => dashboardFilterOption.value,
      (newValue) => {
        model.value.startingDate =
          newValue && newValue.startingDate ? newValue.startingDate : null
        model.value.endingDate =
          newValue && newValue.endingDate ? newValue.endingDate : null
      }
    )

    const areasSalesData = computed(() => {
      return dashboardInfo.value.filterAreaList.filter(
        (item) => item.category === 'Sales'
      )
    })

    const areasRentalsData = computed(() => {
      return dashboardInfo.value.filterAreaList.filter(
        (item) => item.category === 'Rentals'
      )
    })

    return {
      dashboardInfo,
      isLoadingDashboardInfo,
      activeLabel,
      deletedLabel,
      areasSalesData,
      areasRentalsData,
      dashboardDateFilterOptions,
      dashboardFilterOption,
      dateRangeLabel,
      propertiesLabel,
      agentLabel,
      orderByLabel,
      topFivePropertiesLabel,
      topFiveAreaSalesLabel,
      topFiveAreaRentalsLabel,
    }
  },
})
